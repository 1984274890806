import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Table, Spin, Pagination, Input, Button, Switch, Modal } from 'antd';
import { format } from 'date-fns';
import { GET_PAYMENT_METHODS, CREATE_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD_STATUS } from '../mutations/paymentMethods';
import PaymentMethodForm from '../components/paymentMethods/PaymentMethodForm';

const PaymentMethods = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [fetchPaymentMethods, { loading, error, data }] = useLazyQuery(GET_PAYMENT_METHODS, {
    variables: { page: currentPage, perPage: pageSize, search: searchQuery },
  });

  const [createPaymentMethod] = useMutation(CREATE_PAYMENT_METHOD, {
    update: (cache, { data: { createPaymentMethod } }) => {
      if (createPaymentMethod?.paymentMethod) {
        const { paymentMethod } = createPaymentMethod;
        cache.modify({
          fields: {
            paymentMethods(existingPaymentMethods = {}) {
              const newPaymentMethodRef = cache.writeFragment({
                data: paymentMethod,
                fragment: gql`
                  fragment NewPaymentMethod on PaymentMethod {
                    id
                    name
                    createdAt
                    updatedAt
                    active
                    paymentString
                  }
                `
              });
              return {
                ...existingPaymentMethods,
                edges: [...existingPaymentMethods.edges, { node: newPaymentMethodRef }]
              };
            }
          }
        });
      }
    }
  });

  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD);
  const [updatePaymentMethodStatus] = useMutation(UPDATE_PAYMENT_METHOD_STATUS);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  const handleSearch = () => {
    setSearchQuery(searchTerm);
    setCurrentPage(1);
    fetchPaymentMethods({
      variables: {
        page: 1,
        perPage: pageSize,
        search: searchTerm,
      },
    });
  };

  const handleCreatePaymentMethod = () => {
    setSelectedPaymentMethod(null);
    setIsEditMode(false);
    setIsFormModalVisible(true);
  };

  const handleEditPaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setIsEditMode(true);
    setIsFormModalVisible(true);
  };

  const handleFormCancel = () => {
    setIsFormModalVisible(false);
    setSelectedPaymentMethod(null);
    setErrorMessage(null);
  };

  const handleSave = async (values) => {
    setErrorMessage(null);
    try {
      let response;
      if (isEditMode) {
        response = await updatePaymentMethod({
          variables: {
            id: selectedPaymentMethod.id,
            ...values
          }
        });
      } else {
        response = await createPaymentMethod({
          variables: { attributes: values }
        });
      }

      const errors = response.data?.createPaymentMethod?.errors || response.data?.updatePaymentMethod?.errors;
      if (errors && errors.length > 0) {
        setErrorMessage(errors.join(', '));
      } else {
        fetchPaymentMethods(); // Refetch payment methods to update the list
        handleFormCancel(); // Close the modal
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Ocorreu um erro inesperado.');
    }
  };

  const showConfirmModal = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    const { id, active } = selectedPaymentMethod;
    try {
      await updatePaymentMethodStatus({
        variables: { id, active: !active },
        update: (cache, { data: { updatePaymentMethodStatus } }) => {
          const { paymentMethod } = updatePaymentMethodStatus;
          cache.modify({
            id: cache.identify(paymentMethod),
            fields: {
              active(cachedActive) {
                return !active;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Link de pagamento',
      dataIndex: 'paymentString',
      key: 'paymentString',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => format(new Date(text), 'dd/MM/yyyy - HH:mm'),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => format(new Date(text), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={() => showConfirmModal(record)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleEditPaymentMethod(record)}>Editar</Button>
      ),
    },
  ];

  const paymentMethods = data?.paymentMethods?.edges.map(edge => edge.node) || [];
  const { totalCount } = data?.paymentMethods || { pageInfo: {}, totalCount: 0 };

  return (
    <>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <Input
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: 8, width: 300 }}
          onPressEnter={handleSearch}
        />
        <Button type="primary" onClick={handleSearch}>Pesquisar</Button>
        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={handleCreatePaymentMethod}>
          Criar Forma de Pagamento
        </Button>
      </div>
      <Table
        dataSource={paymentMethods}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCount}
        locale={{ items_per_page: '/ página' }}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          fetchPaymentMethods({
            variables: {
              page: page,
              perPage: pageSize,
              search: searchQuery,
            },
          });
        }}
      />
      <Modal
        title={isEditMode ? "Editar Forma de Pagamento" : "Criar Forma de Pagamento"}
        open={isFormModalVisible}
        onCancel={handleFormCancel}
        footer={null}
        maskClosable={false}
        getContainer={false}
      >
        <PaymentMethodForm
          initialValues={selectedPaymentMethod}
          isEditMode={isEditMode}
          onFinish={handleSave}
          onCancel={handleFormCancel}
          errorMessage={errorMessage}
        />
      </Modal>
      <Modal
        title="Confirmação"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        maskClosable={false}
        getContainer={false}
      >
        <p>Tem certeza que deseja continuar?</p>
      </Modal>
    </>
  );
};

export default PaymentMethods;
